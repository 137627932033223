
/**
 * doc.
 * https://blog.csdn.net/qq_37417446/article/details/106794558
 * https://github.com/fengyuanchen/cropperjs#events
 * demo
 * https://fengyuanchen.github.io/cropperjs/
 */
import {
  defineComponent,
  ref,
  toRefs,
  unref,
  watch
} from 'vue';
import { Plus, Delete } from '@element-plus/icons';
import { ElMessageBox } from 'element-plus';
import VueCropper, { VueCropperMethods } from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';

import { uploadImage, ImageResponseData } from '@/services/api';

export type UploadImageEvent = ImageResponseData;

export default defineComponent({
  components: {
    Plus,
    Delete,
    VueCropper
  },
  props: {
    source: {
      type: String,
      default: ''
    },
    // TODO: 改為 croppable
    cropped: {
      type: Boolean,
      default: true
    },
    removable: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9
    },
    type: {
      type: String,
      default: 'images'
    }
  },
  emits: ['upload', 'delete'],
  setup(props, { emit }) {
    const { source } = toRefs(props);
    const imageFile = ref<File | null>(null);
    const croppedImageSource = ref('');
    const previewImageSource = ref(source.value);
    watch(source, (source) => {
      previewImageSource.value = source;
    });

    const cropData = ref('');
    const showDialog = ref(false);
    const cropperRef = ref<VueCropperMethods | null>(null);

    const upload = async() => {
      const { data } = await uploadImage({
        data: {
          image: imageFile.value,
          ...(cropData.value && {
            imageMeta: cropData.value
          }),
          type: props.type
        }
      });

      emit('upload', data);
    };

    const handleImageChange = ({ raw }: UploadFile) => {
      imageFile.value = raw;

      if (props.cropped) {
        croppedImageSource.value = URL.createObjectURL(raw);
        showDialog.value = true;

        return;
      }

      previewImageSource.value = URL.createObjectURL(raw);
      upload();
    };

    const cropAndUpload = async() => {
      const cropper = unref(cropperRef);

      previewImageSource.value = cropper?.getCroppedCanvas().toDataURL() || '';
      cropData.value = JSON.stringify(cropper?.getData(true));

      await upload();

      showDialog.value = false;
    };

    const removeImage = () => {
      previewImageSource.value = '';

      emit('delete');
    };

    const handleBeforeDialogClose = (done: () => void) => {
      ElMessageBox.confirm('Are you sure to close?')
        .then(() => {
          done();
        })
        .catch();
    };

    return {
      croppedImageSource,
      previewImageSource,
      showDialog,
      cropperRef,
      handleImageChange,
      removeImage,
      cropAndUpload,
      handleBeforeDialogClose
    };
  }
});

